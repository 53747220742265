<template>
  <nav class="navbar navbar-expand-lg">
    <router-link to="/" class="navbar-brand text-white">
      <img src="https://lms.ispp.org.in/Content/ISPP-Logo-B.png" alt="LMS Online Tests" width="100"/>
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      @click="collapseNavbar = !collapseNavbar"
    >
      <span class="navbar-toggler-icon">&#x2630;</span>
    </button>

    <div
      class="navbar-collapse"
      :class="{ collapse: collapseNavbar }"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link"
            ><i class="qicon-user"></i>{{ currentUser.name }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0)"
            class="nav-link exit-btn btn btn-danger text-white"
            @click.prevent.stop="onExit"
            ><i class="qicon-cancel"></i> Exit Test</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import quizMixin from "@/mixins/quizMixin";

export default {
  mixins: [quizMixin],
  data() {
    return {
      collapseNavbar: true,
    };
  },
  methods: {
    onExit() {
      if (confirm("Do you really want to exit the test?")) {
        this.exitQuizFromTop();
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  position: relative;
  z-index: 50;

  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  
  margin-bottom: 15px;
}
.nav-link {
  color: #666;
  font-weight: normal;
}
.nav-link:hover {
  color: #333;
}
.router-link-exact-active:not(.navbar-brand) {
  color: #222;
  font-weight: 700;
}
.exit-btn {
  font-weight: bold;
  font-size: 18px;
  border: 1px solid red;
  padding: 5px 10px;
}
</style>
