import storeKeyGenerator from "@/utils/store-key-generator";

export default {
    toggleAlert(state, alertModel){
      if(alertModel){
        state.alert = alertModel;
      }
      else{
        state.alert = null;
        state.alertClosed = true;
        setTimeout(() => {
          state.alertClosed = false;
        }, 0);
      }
    },
    toggleLoader(state, {visible, text}){
      if(visible){
        state.baseLoader = {show: visible, text};
      }
      else{
        state.baseLoader = null;
      }
    },

    setCohorts(state, cohortsList){
      state.cohorts = cohortsList;
    },
    
    addTerms(state, {courseId, termsList}){
      state.terms[courseId] = termsList;
    },
    addSubjects(state, {courseId, termId, subjectsList}){
      const subjectsKey = storeKeyGenerator.generateSubjectsKey(courseId, termId);
      state.subjects[subjectsKey] = subjectsList;
    },

    setCurrentUser(state, user){
        state.currentUser = user;
    },
    setRedirectUrl(state, redirectUrl){
        state.redirectUrl = redirectUrl;
    },
    setAuthToken: (state, token) => state.authToken = token,
    removeAuthToken: (state) => state.authToken = null,
    logout(state){
        state.currentUser = null;
        state.authToken = null;
        state = null;
    }
  }