<template>
  <teleport to="body">
    <div v-if="show" @click="outsideClick" class="backdrop"></div>
    <transition name="upload-popup">
      <dialog open v-if="show" id="base-dialog-popup">
        <header class="bg-primary">
          <slot name="header">
            <h4>{{ title }}</h4>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
        <menu v-if="!fixed">
          <slot name="actions">
            <button class="btn btn-sm btn-primary" @click="tryClose">Close</button>
          </slot>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    outsideClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["close"],
  methods: {
    outsideClick() {
      if (this.outsideClose) {
        this.tryClose();
      }
    },
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
}

header {
  color: white;
  width: 100%;
  padding: 12px;
}

header h4 {
  margin: 0;
  font-size: 20px;
}

section {
  padding: 15px;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.upload-popup-enter-from {
  opacity: 0.2;
  transform: translateX(-30px);
}
.upload-popup-leave-to {
  opacity: 0.2;
  transform: translateX(30px);
}
.upload-popup-enter-active {
  transition: all 0.15s ease-out;
}
.upload-popup-leave-active {
  transition: all 0.15s ease-in;
}
.upload-popup-enter-to,
.upload-popup-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
