// https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863

function getTitle (vm) {
    const { title } = vm.$options
    
    if (title) {
      return typeof title === 'function'
        ? title.call(vm)
        : title
    }
  }
  export default {
    created () {    
      const title = getTitle(this)      
      // Don't add a default value as this mixin is going to be called for all the components rendered on the page. 
      // (So for non-routable components title will be undefined)
      // title should be provided only for routable views/pages/components
      if(title)
      { 
        document.title = title;
      }
    }
  }