<template>
  <div class="text-danger text-center">
    <h2 class="d-block">{{ displayHeading }}</h2>
    <p v-if="displayDetails">{{ displayDetails }}</p>
  </div>
</template>
<script>
const headingMessage = {
  unauthorized: "Unauthorized",
  forbidden: "Forbidden",
  error: "Error",
  notfound: "Not Found",
};
const details = {
  unauthorized: "Login required to access this page",
  forbidden: "You are not authorized to access this page",
  notfound: "Requested page does not exist. Please check the url and try again",
};
export default {
  title() {
    return this.displayHeading;
  },
  props: {
    type: { type: String, required: true, default: "Blank" },
  },
  computed: {
    displayHeading() {
      return headingMessage[this.type] || "Unknown";
    },
    displayDetails() {
      return details[this.type] || null;
    },
  },
};
</script>
