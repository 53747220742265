import {mapActions, mapGetters, mapMutations} from "vuex"

export default {
    computed: {
        ...mapGetters('student', ["selectedCourseId", "selectedQuiz", "totalQuestions", "studentQuiz", "studentQuizId", "jumpQuestions", "responsePendingQuestion", "questions","totalQuestionsServed","currentQuestion", "exitingQuiz"]),
        ...mapGetters(["currentUser", "isLoggedIn", "isAdmin"]),
        isQuizInProcess(){
            return !! this.selectedQuiz?.id;
        }
    },
    methods: {
        ...mapActions('student',["setSelectedQuiz", "resetSelectedQuiz","setStudentQuiz","addQuestion", "updateCurrentQuestion", "clearQuiz", "setJumpQuestions", "setVisited", "setVisitedAndDone"]),
        ...mapMutations('student',["exitQuizFromTop"]),
    },
}