export default class StorageService {
    get type(){
        return window.localStorage;
    }
    add(key, value){
        this.type.setItem(key, JSON.stringify(value));
    }
    remove(key){
        this.type.removeItem(key);
    }
    reset(){
        this.type.clear();
    }
    get(key){
        const data = this.getRaw(key)
        return data ? JSON.parse(data) : null;
    }
    getRaw(key){
        return this.type.getItem(key);
    }
}