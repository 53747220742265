import initialState from "./adminState";

const mutations = {
    setBlankQuestion(state, blankQuestion){
        state.blankQuestion = blankQuestion;
    },
    setParsedQuestions(state, parsedQuestions){
        state.parsedQuestions = parsedQuestions;
    },

    setSelectedCohortId(state, selectedCohortId){
        state.selectedCohortId = selectedCohortId;
    },
    setSelectedTermId(state, selectedTermId){
        state.selectedTermId = selectedTermId;
    },
    setSelectedSubjectId(state, selectedSubjectId){
        state.selectedSubjectId = selectedSubjectId;
    },

    resetAdminState(state){
        state = initialState;
        state = null;
    }
};
export default mutations;