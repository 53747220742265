import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HeadingBreadcrumb from './components/HeadingBreadcrumb.vue'
import BaseDialog from './components/BaseDialog.vue'
import titleMixin from './mixins/titleMixin'
import HttpService from './services/HttpService'

const app = createApp(App);

window.onerror = function (msg, url, line, col, error) {
    //code to handle or report error goes here
    console.log({'window-onerror': msg});
    console.log({msg, url, line, col, error});
    
    HttpService.addLog({type: 'window-onerror', url, details: msg});
}
window.addEventListener('unhandledrejection', function(event) {
    //handle error here
    //event.promise contains the promise object
    //event.reason contains the reason for the rejection
    console.log({'promise-error - unhandledrejection': event.reason});
    console.log({promise: event.promise, reason: event.reason});
    
    HttpService.addLog({type: 'promise-error - unhandledrejection', url: window.location.href, details: event.reason?.message});
});
app.errorHandler = (err, componentInstance, info, a,b,c) =>{
    console.log({'global-vue-error': err});
    console.log({err, componentInstance, info, a,b,c});
    
    HttpService.addLog({type: 'global-vue-error', url: window.location.href, details: err});
}

app.mixin(titleMixin);

app.component("HeadingBreadcrum", HeadingBreadcrumb);
app.component("BaseDialog", BaseDialog);

router.beforeEach((to, from) => {
    const {requiresAuth, requiresRole} = to.meta;
    if(requiresAuth){
        if(store.getters.isLoggedIn){
            if(requiresRole){
                const requiresRoles = requiresRole.toLowerCase().split(',');
                const currentRole = store.getters.currentUser?.role.toLowerCase();
                if(currentRole && requiresRoles.some(r=> r === currentRole)){
                    return true;
                }
                else{
                    return {name: 'error', params:{type: 'forbidden'}};
                }
            }
        }
        else{
            return {name: 'error', params:{type: 'unauthorized'}};
        }
    }
});

app.use(store).use(router).mount('#app')
