<template>
  <nav class="navbar navbar-expand-lg">
    <router-link to="/" class="navbar-brand text-white"
      >
        <img src="https://lms.ispp.org.in/Content/ISPP-Logo-B.png" alt="LMS Online Tests" width="100"/>
      </router-link
    >
    <button
      class="navbar-toggler"
      type="button"
      @click="collapseNavbar = !collapseNavbar"
    >
      <span class="navbar-toggler-icon">&#x2630;</span>
    </button>

    <div
      v-if="isAdmin"
      class="navbar-collapse"
      :class="{ collapse: collapseNavbar }"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/admin/manage-quizes" class="nav-link"
            >Manage Tests</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/admin/student-quizes" class="nav-link"
            >Student Tests</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/admin/manage-quizes/add" class="nav-link"
            >Add new Test</router-link
          >
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link">{{ name }}</a>
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0)"
            class="nav-link btn btn-outline-danger"
            @click.prevent.stop="onLogout"
            ><i class="qicon-logout"></i> Go back to LMS</a
          >
        </li>
      </ul>
    </div>

    <div
      v-else-if="isStudent"
      class="navbar-collapse"
      :class="{ collapse: collapseNavbar }"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/student/my-quizes" class="nav-link">My Tests</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/student/take-quiz" class="nav-link">Take a Test</router-link>
        </li>
      </ul>
      <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link"
            ><i class="qicon-user"></i> {{ name }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="javascript:void(0)"
            class="nav-link btn btn-outline-danger"
            @click.prevent.stop="onLogout"
            ><i class="qicon-logout"></i> Go back to LMS</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "User",
    },
  },
  data() {
    return {
      collapseNavbar: true,
    };
  },
  computed: {
    ...mapGetters(["redirectUrl", "isAdmin", "isStudent"]),
  },
  methods: {
    ...mapActions(["logout", "showBaseLoader"]),
    onLogout() {
      const redirectTo = this.redirectUrl || "/";
      this.showBaseLoader("Logging out...");
      this.logout();
      this.showBaseLoader("Redirecting...");
      window.location.replace(redirectTo);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: relative;
  z-index: 50;

  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);

  margin-bottom: 15px;
}
.nav-link {
  color: #666;
  font-weight: normal;
}
.nav-link:hover {
  color: #333;
}
.router-link-exact-active:not(.navbar-brand) {
  color: #222;
  font-weight: 700;
}
</style>
