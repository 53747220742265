const actions = {
    setSelectedQuiz: ({ commit }, quiz) => commit("setSelectedQuiz", quiz),

    resetSelectedQuiz: ({ commit }) =>
      commit("setSelectedQuiz", {
        id: null,
        name: null,
        questions: 0,
        duration: 0,
      }),
      
    setStudentQuiz: ({commit}, {studentQuizId}) => commit("setStudentQuiz", {studentQuizId, questions: []}),

    setJumpQuestions: ({commit}, jumpQuestions) => commit("setJumpQuestions", jumpQuestions),
    setVisited({commit}, qid){
      commit("setVisited", qid);
    },
    setVisitedAndDone({commit}, qid){
      commit("setVisitedAndDone", qid);
    },

    addQuestion({commit, getters}, newQuestion){
      const newQuestionCopy = {...newQuestion};
      if(getters.questions.some(q=> q.id === newQuestionCopy.id))
      {
        //Already in the list
      }
      else{
        commit("addQuestion", newQuestionCopy);
      }
    },
    updateCurrentQuestion({commit, getters}, updatedQuestion){
      const updatedQuestionCopy = {...updatedQuestion};
      if(getters.questions.some(q=> q.id === updatedQuestionCopy.id)){
        commit("updateCurrentQuestion", updatedQuestionCopy);
      }
    },
    clearQuiz: ({ commit }) => commit("resetQuiz")
  };
 export default actions;