export default [
  {
    path: "/student",
    meta: { requiresAuth: true, requiresRole: "student" },
    children: [
      {
        path: "my-quizes",
        name: "my-quizes",
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/students/MyQuizesView.vue"
          ),
      },
      {
        path: "my-quizes/:studentQuizId/view",
        name: "my-quiz-view",
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/shared/ViewStudentQuizView.vue"
          ),
      },
      {
        path: "take-quiz",
        name: "take-quiz",
        component: () =>
          import(
            /* webpackChunkName: "student" */ "../views/students/TakeQuizView.vue"
          ),
      },
      {
        path: "in-quiz",
        name: "in-quiz",
        component: () =>
          import(
            /* webpackChunkName: "in-quiz" */ "../views/students/InQuizView.vue"
          ),
      },
    ],
  },
];
