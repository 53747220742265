export default {
  //-- quiz
  selectedQuiz: {
    id: null,
    name: null,
    totalQuestions: 0,
    duration: 0, // minutes
  },
  jumpQuestions:[], //{qNo: 1, qId: 1, visited: false}
  studentQuiz: {
    studentQuizId: 0,
    questions:[] // [{id: 0, isFirst: false, isLast: false, done: false}]
  },
  exitingQuiz: false,
};



