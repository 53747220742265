<template>
  <div class="home">
    <h1>Welcome to Online Tests App</h1>
  </div>
</template>

<script>
export default {
  title: "Online Tests",
  name: "HomeView",
};
</script>
