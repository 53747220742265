import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ErrorView from "../views/ErrorView.vue";
import adminRoutes from "./adminRoutes";
import studentRoutes from "./studentRoutes";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  ...adminRoutes,
  ...studentRoutes,
  {
    path: "/error/:type",
    name: "error",
    component: ErrorView,
    props: true,
  },
  {
    path: '/:catchAll(.*)*',
    name: "catch-all",
    redirect: "/error/notfound",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
