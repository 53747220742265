import studentActions from "./studentActions"
import studentGetters from "./studentGetters"
import studentMutations from "./studentMutations"
import studentState from "./studentState"

export default {
    namespaced: true,
    state(){
        return studentState
    },
    actions: studentActions,
    mutations: studentMutations,
    getters: studentGetters
}