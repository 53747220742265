import { createStore } from 'vuex'
import adminModule from "./modules/admin";
import studentModule from './modules/student';
import rootActions from './rootActions';
import rootGetters from './rootGetters';
import rootMutations from './rootMutations';

export default createStore({
  modules: {admin: adminModule, student: studentModule},
  
  state: {
    alert: null,
    alertClosed: false,
    baseLoader: null,

    // Cohorts with Course Id
    cohorts: [], 
    
    // Terms under the courses  
    // { cohort1: [Term1, Term2, Term3]}
    terms: {},
    
    // CourseTermSubject - Subjects under the selected course and term
    // {Cohort1_Term1 : [Subject1, Subject2, Subject3...]}
    subjects: {}, 
    
    currentUser: null,
    authToken: null,
    redirectUrl: '/'
  },
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions
})
