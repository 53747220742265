export default class ApiCallResponse{
    constructor(statusCode, success, response, isTokenInvalid, isTokenEmptyOrExpired, errorMessage){
        this.statusCode = statusCode || 0;
        this.success = !!success;
        this.response = response;
        this.isTokenInvalid = !!isTokenInvalid;
        this.isTokenEmptyOrExpired = !!isTokenEmptyOrExpired;
        this.errorMessage = errorMessage || null;
    }
    get ErrorMessage(){
        return this.errorMessage || this.response?.message || this.response?.detail || null;
    }
}