import initialState from "./studentState";

const mutations = {
    setSelectedQuiz(state, quiz) {
      state.selectedQuiz = quiz;
    },
    setStudentQuiz(state, studentQuiz){
      state.studentQuiz = studentQuiz;
      state.studentQuiz.questions = [];
    },
    setJumpQuestions: (state, jumpQuestions) => state.jumpQuestions = jumpQuestions,
    setVisited(state, qid){
      const q = state.jumpQuestions.find(x=> x.qId === qid);
      if(q){
        q.visited = true;
      }
    },
    setVisitedAndDone(state, qid){
      const q = state.jumpQuestions.find(x=> x.qId === qid);
      if(q){
        q.visited = true;
        q.done = true;
      }
    },
    addQuestion(state, question){
      state.studentQuiz.questions.push(question);
    },
    updateCurrentQuestion(state, updatedQuestion){
      const index = state.studentQuiz.questions.findIndex(q=> q.id === updatedQuestion.id);
      state.studentQuiz.questions[index] = updatedQuestion;
    },
    resetQuiz(state){
      state.selectedQuiz = {
        id: null,
        name: null,
        totalQuestions: 0,
        duration: 0, // minutes
      };
      state.studentQuiz = {
        studentQuizId: 0,
        questions:[] // [{id: 0, isFirst: false, isLast: false, done: false}]
      };
      state.exitingQuiz = false;
    },
    exitQuizFromTop: (state) => state.exitingQuiz = true,
    resetStudentState(state){
        state = initialState;
        state = null;
    }
  };
 export default mutations;