const keys = {};

Object.defineProperty(keys, "Token", {
    value: "tkn"
});
Object.defineProperty(keys, "User", {
    value: "usr"
});
Object.defineProperty(keys, "RedirectUrl", {
    value: "to"
});

export default keys;