export default {
    alert: ({alert}) => alert,
    alertClosed: ({alertClosed}) => alertClosed,

    baseLoader: ({baseLoader}) => baseLoader,
    baseApiUrl: ({baseApiUrl}) => baseApiUrl,

    cohorts: ({cohorts}) => cohorts,
    terms: ({terms}) => terms,
    subjects: ({subjects}) => subjects,

    currentUser: ({currentUser}) => currentUser,
    token: ({authToken}) => authToken,
    
    isLoggedIn: ({currentUser, authToken}) => !!currentUser && !!authToken,
    isAdmin: ({currentUser}) => currentUser.role.toLowerCase() === 'admin' || currentUser.role.toLowerCase() === 'faculty',
    isStudent: ({currentUser}) => currentUser.role.toLowerCase() === 'student',

    redirectUrl: ({redirectUrl}) => redirectUrl
  }