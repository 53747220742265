<template>
  <teleport to="body">
    <div v-if="baseLoader.show" class="backdrop"></div>
    <dialog open v-if="baseLoader.show">
      <div class="text-center">
        <i class="qicon-spin4 qanimate-spin icon"></i>
        <label>{{ baseLoader.text || "Loading..." }}</label>
      </div>
    </dialog>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["baseLoader"]),
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 101;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 102;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  color: #fff;
}
.icon {
  font-size: 30px;
  display: block;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
