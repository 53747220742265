import keys from "@/constants/keys";
import ApiCallResponse from "@/models/ApiCallResponse";
import StorageService from "./StorageService";

export default class HttpService {
  constructor() {
    this.storage = new StorageService();
  }
  isTokenExpired(token) {
    return (
      !!token && Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000
    );
  }
  isTokenSecure(token) {
    const tokenIssuer = JSON.parse(atob(token.split(".")[1])).iss;
    if (tokenIssuer) {
      return process.env.VUE_APP_API_BASE_URL.startsWith(tokenIssuer);
    }
    return false;
  }
  isTokenValid(token) {
    return !this.isTokenExpired(token) && this.isTokenSecure(token);
  }
  static addLog(body){
    try {
      if(body){
        const headers = {"Content-Type": "application/json;charset=utf-8"};
        fetch(`${process.env.VUE_APP_API_BASE_URL}/common/uilog`, { headers, method: "POST", body: JSON.stringify(body) });
      }
    } catch (error) {
      
    }
  }
  async callApi(url, options, parseResult, skipToken = false, skipContentType = false) {
    const result = new ApiCallResponse();
    result.success = false;
    const token = this.storage.get(keys.Token);
    if(!skipToken && !token){
        result.errorMessage = "Missing token. Please login again";
        return result;
    }
    if (!skipToken && this.isTokenExpired(token)) {
      result.isTokenEmptyOrExpired = true;

      const loginResponse = await this.handleTokenExpired();
      if(!loginResponse.success)
        {
            //Token expired, tried to login, but no success
            return loginResponse;
        }
        else{
            // Token was expired, silently logged-in succesfully
            // Re-run this method to return the actual response to the original caller
            return this.callApi(url, options, parseResult, skipToken);
        }
    } else if (!skipToken && !this.isTokenSecure(token)) {
      result.isTokenInvalid = true;
    } else if (!url) {
      result.errorMessage = "Url is missing";
    } else {
      const headers = { ...options.headers };
      if (!skipToken) {
        headers.Authorization = `Bearer ${token}`;
      }
      if(!skipContentType)
      {
        headers["Content-Type"] = "application/json;charset=utf-8";
      }

      url = `${process.env.VUE_APP_API_BASE_URL}/${url.toString().startsWith("/") ? url.toString().substr(1) : url}`;

      const requestOptions = {
        ...options,
        headers,
      };

      let response = null;
      try{
        response = await fetch(url, requestOptions);
      }
      catch(err){
        if(err?.message === 'Failed to fetch'){
          result.errorMessage = "Unable to connect to the api server";
          return result;
        }
        throw err;
      }
      result.statusCode = response.status;

      if (response.status === 401) {
        result.errorMessage = "Unauthorized Request";
        return result;
      }
      else if (response.status === 403) {
        result.errorMessage = "Permission Denied";
        return result;
      }

      if (response.ok) {
        result.success = true;
        if(response.status === 204)
        {return result;}
      }
      if (parseResult) {
        result.response = await response.json();
        if(response.status === 400 && result.response.errors){
          // 400 BadRequest default ApiController attributes ModelState Validation errors
            // {"errors":{"Options[2].Content":["The Content field is required."],"Options[3].Content":["The Content field is required."]}}
            result.response.detail = '<ul>'
            for(const key in result.response.errors){
              if(result.response.errors.hasOwnProperty(key)){
                result.response.detail+= `<li>${key} - ${result.response.errors[key].join(', ')}</li>`;
              }
            }
            result.response.detail += '</ul>'
        }
      } else {
        result.response = response;
      }
    }
    return result;
  }
  async get(url, parseResult = true) {
    const options = {
      method: "GET",
    };
    return await this.callApi(url, options, parseResult);
  }

  async post(url, body, parseResult = true) {
    const options = {
      method: "POST",
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return await this.callApi(url, options, parseResult);
  }

  async patch(url, body, parseResult = true) {
    const options = {
      method: "PATCH",
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return await this.callApi(url, options, parseResult);
  }

  async put(url, body, parseResult = true) {
    const options = {
      method: "PUT",
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return await this.callApi(url, options, parseResult);
  }

  async delete(url, body, parseResult = true) {
    const options = {
      method: "DELETE",
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return await this.callApi(url, options, parseResult);
  }

  async formDataPost(url, body, parseResult = true) {
    const options = {
      method: "POST",
      body
    };
    return await this.callApi(url, options, parseResult, false, true);
  }


  async login(body) {
    const options = {
      method: "POST",
      body: JSON.stringify(body),
    };
    return await this.callApi("auth/silent-login", options, true, true);
  }

  async handleTokenExpired() {
    const existingUser = this.storage.get(keys.User);
    if (existingUser) {
      return await this.login("auth/silent-login", existingUser);
    }
    const result = new ApiCallResponse();
    result.success = false;
    result.errorMessage =
      "Login expired. Tried to login but user data does not exist.";
    return result;
  }
}
