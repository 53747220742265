import HttpService from "@/services/HttpService";

const actions = {
    async getOrAddBlankQuestion(context, {quizId, tempQuestionId}){
        let blankQuestion = context.getters.blankQuestion;
        if(!blankQuestion){
            try {
                const httpService = new HttpService();      
                context.commit("toggleLoader", {visible: true, text: 'Creating new question...'}, {root: true});
                const result = await httpService.get(`AdminQuiz/quizes/${quizId}/questions/blank/${tempQuestionId}`);
                if (result.success) {
                    blankQuestion = result.response;
                    context.commit("setBlankQuestion", blankQuestion);
                    const questionCopy = JSON.parse(JSON.stringify(blankQuestion)); //Create a deep copy of the question
                    questionCopy.quizId = quizId;
                    questionCopy.id = tempQuestionId;
                    return questionCopy
                } else {
                  context.commit("toggleAlert", getErrorAlertModel(result.ErrorMessage || "Error in adding question"), {root: true});
                }
              } catch (ex) {
                context.commit("toggleAlert", getErrorAlertModel("Could not add new question"), {root: true});
                console.error(ex);
              }
              finally{
                context.commit("toggleLoader", {visible: false}, {root: true});
              }
        }
        else{
            const questionCopy = JSON.parse(JSON.stringify(blankQuestion)); //Create a deep copy of the question
            questionCopy.quizId = quizId;
            questionCopy.id = tempQuestionId;
            return questionCopy
        }
        return null;
    },
    addParsedQuestions({commit}, parsedQuestions){
        commit('setParsedQuestions', parsedQuestions);
    },
    clearParsedQuestions({commit}){
        commit('setParsedQuestions', null);
    },

    setSelectedCohortId({commit}, selectedCohortId){
      commit('setSelectedCohortId', selectedCohortId);
  },
  setSelectedTermId({commit}, selectedTermId){
    commit('setSelectedTermId', selectedTermId);
  },
  setSelectedSubjectId({commit}, selectedSubjectId){
    commit('setSelectedSubjectId', selectedSubjectId);
  }
};
export default actions;