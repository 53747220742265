import Keys from "@/constants/keys";
import AlertModel from "@/models/AlertModel";
import HttpService from "@/services/HttpService";
import StorageService from "@/services/StorageService";
import storeKeyGenerator from "@/utils/store-key-generator";

const getErrorAlertModel = (text, title) =>
  AlertModel.Alert(text, title || "Error", "danger");

export default {
  showGlobalAlert(context, alertModel) {
    context.commit("toggleAlert", alertModel);
  },
  hideGlobalAlert(context) {
    context.commit("toggleAlert");
  },
  showBaseLoader(context, text) {
    context.commit("toggleLoader", { visible: true, text });
  },
  hideBaseLoader(context) {
    context.commit("toggleLoader", { visible: false });
  },
  
  async getCohorts({commit, getters}){
    try {
      let cohorts = getters.cohorts;
      if(cohorts && cohorts.length){
        return cohorts;
      }
      const httpService = new HttpService();
      const result = await httpService.get("common/cohorts");
      if (result.success) {
        cohorts = result.response || [];
        commit("setCohorts", cohorts);
        return cohorts;
      } else {
        commit(
          "toggleAlert",
          getErrorAlertModel(result.ErrorMessage || "Error in loading cohorts")
        );
      }
    } catch (ex) {
      commit(
        "toggleAlert",
        getErrorAlertModel(`Could not load cohorts - ${ex}`)
      );
      console.error(ex);
    }
  },

  async getTerms({commit, getters}, courseId){
    try {
      const terms = getters.terms;
      if(terms){
        const courseTerms = terms[courseId];
        if(courseTerms && courseTerms.length){
          return courseTerms;
        }
      }
      
      const httpService = new HttpService();
      const result = await httpService.get(`common/cohorts/${courseId}/terms`);
      if (result.success) {
        const courseTerms = result.response || [];
        commit("addTerms", {courseId, termsList: courseTerms});
        return courseTerms;
      } else {
        commit(
          "toggleAlert",
          getErrorAlertModel(result.ErrorMessage || "Error in loading terms")
        );
      }
    } catch (ex) {
      commit(
        "toggleAlert",
        getErrorAlertModel(`Could not load terms - ${ex}`)
      );
      console.error(ex);
    }
  },

  
  async getSubjects({commit, getters}, {courseId, termId}){
    try {
      const subjects = getters.subjects;
      const subjectsKey = storeKeyGenerator.generateSubjectsKey(courseId, termId);
      if(subjects){
        const courseTermSubjects = subjects[subjectsKey];
        if(courseTermSubjects && courseTermSubjects.length){
          return courseTermSubjects;
        }
      }
      
      const httpService = new HttpService();
      const result = await httpService.get(`common/cohorts/${courseId}/terms/${termId}/subjects`);
      if (result.success) {
        const courseTermSubjects = result.response || [];
        commit("addSubjects", {courseId, termId, subjectsList: courseTermSubjects});
        return courseTermSubjects;
      } else {
        commit(
          "toggleAlert",
          getErrorAlertModel(result.ErrorMessage || "Error in loading subjects")
        );
      }
    } catch (ex) {
      commit(
        "toggleAlert",
        getErrorAlertModel(`Could not load subjects - ${ex}`)
      );
      console.error(ex);
    }
  },
  
  async login(context, { userId, role, redirectUrl }) {
    const storage = new StorageService();
    const httpService = new HttpService();

    //If userId and role are not provided, check if these exist in the storage, (if not then do a fresh login from server )
    if (!userId || !role) {
      // Check if token exists in the Storage and isValid, (So that we can skip the http call and fetch data from storage only)
      const existingToken = storage.get(Keys.Token);
      if (existingToken) {
        if (httpService.isTokenValid(existingToken)) {
          // Check if user details exist in the storage
          const existingUser = storage.get(Keys.User);
          if (existingUser) {
            // both token and user detail exist in the storage, no need to call api, set these value to the Vuex store
            context.commit("setAuthToken", existingToken);
            context.commit("setCurrentUser", existingUser);
            const redirectTo = storage.get(Keys.RedirectUrl);
            if(redirectTo){
              context.commit("setRedirectUrl", redirectTo);
            }
            return true; // Return from here, no need to call api, as we already have token and user info
          } else {
            // User details does not exist in the storage, so remove the token from the storage,
            // Its an unstable state, so show the unauthorized alert
            storage.remove(Keys.Token);

            context.commit("toggleAlert", getErrorAlertModel("Unauthorized request", "Unauthorized"));
            return;
          }
        }
      }
      else{
        // User id and role were not provided from url and do not exist in the storage, so its an unauthorized access

        context.commit("toggleAlert", getErrorAlertModel("Unauthorized request", "Unauthorized"));
        return;
      }
    }
    try {
      context.commit("toggleLoader", { visible: true, text: "Logging-in..." });

      const result = await httpService.login({ userId, role });
      if (result.success && !!result.response) {
        const { name, role, token } = result.response;
        if (name && role && token) {
          const newUser = { name, role };

          context.commit("setAuthToken", token);
          context.commit("setCurrentUser", newUser);
          
          if(redirectUrl){
            context.commit("setRedirectUrl", redirectUrl);            
            storage.add(Keys.RedirectUrl, redirectUrl);
          }

          storage.add(Keys.Token, token);
          storage.add(Keys.User, newUser);

          return;
        } else {
          context.commit(
            "toggleAlert",
            getErrorAlertModel(
              result.ErrorMessage || "Could not get user details"
            )
          );
        }
      } else {
        context.commit(
          "toggleAlert",
          getErrorAlertModel(result.ErrorMessage || "Error in login")
        );
      }
    } catch (err) {
      console.error(err);
      context.commit(
        "toggleAlert",
        getErrorAlertModel(err || "Error in login")
      );
    } finally {
      context.commit("toggleLoader", { visible: false });
    }

    storage.reset();
    context.commit("logout");
  },
  setAuthToken: (context, token) => context.commit("setAuthToken", token),
  removeAuthToken: (context) => context.commit("removeAuthToken"),
  logout(context) {
    const storage = new StorageService();
    storage.reset();

    context.commit("logout");
    context.commit("admin/resetAdminState");
    context.commit("student/resetStudentState");
  },
};
