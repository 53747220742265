<template>
  <h3 class="text-themecolor mb-0 mt-0">{{ heading }}</h3>
  <ol v-if="breadcrumbs && breadcrumbs.length" class="breadcrumb">
    <li
      v-for="b in breadcrumbs"
      :key="b.name"
      :class="['breadcrumb-item', { active: !b.link }]"
    >
      <router-link v-if="b.link" :to="b.link" class="link">{{ b.name }}</router-link>
      <a v-else href="javascript:void(0)">{{ b.name }}</a>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="css" scoped>
h3 {
  line-height: 30px;
  font-size: 21px;
  margin-bottom: 10px;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;

  margin-bottom: 0px;

  padding: 0px;
  background: transparent;
  font-size: 12px;
  padding-bottom: 20px;
}
.breadcrumb-item > a {
  color: #6c757d;
}
.breadcrumb-item > a.link {
  color: #3169B2 !important;
}
.breadcrumb-item > a:hover,
.breadcrumb-item > a:focus {
  text-decoration: none;
}
.breadcrumb-item > a.link:hover,
.breadcrumb-item > a.link:focus {
  color: #0056b3;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "≫";
  color: #a6b7bf;
  font-size: 14px;
}
</style>
