<template>
  <navbar v-if="isLoggedIn && !isQuizInProcess" :name="userName" />
  <QuizNavbar v-if="isLoggedIn && isQuizInProcess" />
  <div class="container-fluid">
    <div v-if="isLoggedIn" class="row">
      <div class="col-12">
        <router-view v-slot="slotProps">
          <transition name="route" mode="out-in">
            <component :is="slotProps.Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <h5 class="text-danger" v-if="errorMessage">
      <br />
      {{ errorMessage }}
      <br />
    </h5>
    <base-loader v-if="showLoader" />
    <Modal :show="showModal" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import QuizNavbar from "@/components/students/QuizNavbar.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import Modal from "@/components/Modal.vue";
import quizMixin from "./mixins/quizMixin";
export default {
  components: { Navbar, QuizNavbar, BaseLoader, Modal },
  mixins: [quizMixin],
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isStudent() {
      return this.$store.getters.isAdmin;
    },
    userName() {
      return this.$store.getters.currentUser?.name || "User";
    },
    showLoader() {
      return !!this.$store.getters.baseLoader && this.$store.getters.baseLoader.show;
    },
    showModal() {
      return !!this.$store.getters.alert;
    },
  },
  methods: {
    async tryLogin(userId, role, redirectTo) {
      const alreadyLoggedIn = await this.$store.dispatch("login", {
        userId,
        role,
        redirectUrl: redirectTo,
      });
      if (alreadyLoggedIn) {
        return; // Do nothing, be on the same page
      }
      if (this.isLoggedIn) {
        if (this.isAdmin) {
          this.$router.replace({ name: "manage-quizes" });
          return;
        } else {
          this.$router.replace({ name: "my-quizes" });
          return;
        }
      }
      this.$router.replace("/");
    },
  },
  created() {
    if (!this.isLoggedIn) {
      var query = new URLSearchParams(window.location.search);
      const userId = query.get("uid") || query.get("userId") || query.get("userid");
      const role = query.get("Role") || query.get("role");
      const redirectTo = query.get("redirectTo") || query.get("redirectto");
      this.tryLogin(userId, role, redirectTo);
    }
  },
};
</script>

<style>
html,
body,
* {
  font-family: "Poppins", sans-serif;
}
body {
  background: #f2f4f8;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.text-themecolor {
  color: #3169B2 !important;
}

.border-themecolor {
  border-color: #01c0c8 !important;
}

.card-header {
    background: #009efb;
    border-color: #009efb;
}
.card-header h5{
    color: #FFF;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.route-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.route-enter-active {
  transition: all 0.185s ease-out;
}
.route-leave-active {
  transition: all 0.185s ease-in;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
