const getters = {
    blankQuestion(state){
        return state.blankQuestion;
    },
    parsedQuestions: ({parsedQuestions}) => parsedQuestions,
    isUploadingQuestions: ({parsedQuestions}) => !!parsedQuestions && parsedQuestions.length > 0,
    
    selectedCohortId: ({selectedCohortId}) => selectedCohortId,
    selectedTermId: ({selectedTermId}) => selectedTermId,
    selectedSubjectId: ({selectedSubjectId}) => selectedSubjectId
};
export default getters;