<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header" :class="bgClass">
            <slot name="header">
              <h3 :class="headerTextColor">{{ alert.title || "Alert" }}</h3>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <p v-if="alert.text" :class="textClass" v-html="alert.text"></p>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button
                class="modal-default-button btn"
                :class="btnClass"
                @click="hideGlobalAlert()"
              >
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    show: Boolean,
  },
  computed: {
    ...mapGetters(["alert", "alertClosed"]),
    showAlert() {
      return this.alert && !this.alertClosed;
    },
    bgClass() {
      return this.alert.contextClass ? `bg-${this.alert.contextClass}` : "";
    },
    textClass() {
      return this.alert.contextClass === "danger" ? `text-danger` : "";
    },
    headerTextColor() {
      return this.alert.contextClass ? `text-white` : "";
    },
    btnClass() {
      return this.alert.contextClass ? `btn-${this.alert.contextClass}` : "btn-default";
    },
  },
  methods: {
    ...mapActions(["hideGlobalAlert"]),
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
</style>
