const getters = {
    selectedQuiz: ({ selectedQuiz }) => selectedQuiz,
    totalQuestions: ({selectedQuiz}) => selectedQuiz.totalQuestions,

    studentQuiz: ({studentQuiz}) => studentQuiz,
    studentQuizId: ({studentQuiz}) => studentQuiz.studentQuizId,

    jumpQuestions: ({jumpQuestions}) => jumpQuestions,
    responsePendingQuestion: ({jumpQuestions}) => jumpQuestions.filter(q=> !q.done)?.map(q=> q.qId),

    questions: ({studentQuiz}) => studentQuiz.questions,
    totalQuestionsServed: ({studentQuiz}) => studentQuiz.questions.length,
    currentQuestion: ({studentQuiz}) => studentQuiz.questions.slice(-1),
    exitingQuiz: ({exitingQuiz}) => exitingQuiz

  };
 export default getters;