import adminState from "./adminState"
import adminActions from "./adminActions"
import adminGetters from "./adminGetters"
import adminMutations from "./adminMutations"

export default {
    namespaced: true,
    state(){
        return adminState;
    },
    actions: adminActions,
    getters: adminGetters,
    mutations: adminMutations
}