export default [
  {
    path: "/admin",
    meta: { requiresAuth: true, requiresRole: "admin,faculty" },
    children: [
      {
        path: "manage-quizes",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/ManageQuizesView.vue'),
        children: [
          {
            path: "",
            name: "manage-quizes",
            component: () =>
              import(
                /* webpackChunkName: "admin-quiz" */ "../views/admin/ManageQuizesView.vue"
              ),
          },
          {
            path: "add",
            //name: 'add-quiz',
            component: () =>
              import(
                /* webpackChunkName: "admin-add-edit-quiz" */ "../views/admin/AddEditQuizView.vue"
              ),
            children: [
              {
                path: "",
                name: "add-quiz",
                component: () =>
                  import(
                    /* webpackChunkName: "admin-add-edit-quiz" */ "../components/admin/AddEditQuizInfo.vue"
                  ),
              },
            ],
          },
          {
            path: "edit/:id",
            component: () =>
              import(
                /* webpackChunkName: "admin-add-edit-quiz" */ "../views/admin/AddEditQuizView.vue"
              ),
            children: [
              {
                path: "",
                name: "edit-quiz",
                component: () =>
                  import(
                    /* webpackChunkName: "admin-add-edit-quiz" */ "../components/admin/AddEditQuizInfo.vue"
                  ),
              },
              {
                path: "questions",
                name: "edit-quiz-questions",
                component: () =>
                  import(
                    /* webpackChunkName: "admin-add-edit-quiz" */ "../components/admin/AddEditQuizQuestions.vue"
                  ),
              },
            ],
          },
          {
            path: "upload-questions",
            name: "upload-questions",
            component: () =>
              import(
                /* webpackChunkName: "admin-quiz" */ "../views/admin/UploadQuizView.vue"
              ),
          },
        ],
      },
      {
        path: "student-quizes",
        children: [
          {
            path: "",
            name: "student-quizes",
            component: () =>
              import(
                /* webpackChunkName: "student-quizes" */ "../views/admin/StudentQuizesView.vue"
              ),
          },
          {
            path: ":studentQuizId",
            name: "student-quiz-review",
            component: () =>
              import(
                /* webpackChunkName: "student-quiz" */ "../views/admin/StudentQuizView.vue"
              ),
          },
        ],
      },
      {
        path: "student-quizes/:studentQuizId/view",
        name: "student-quiz-view",
        component: () =>
          import(
            /* webpackChunkName: "student-quiz" */ "../views/shared/ViewStudentQuizView.vue"
          ),
      },
    ],
  },
];
